import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import gsap from 'gsap';

import { Result as Mandate } from 'api/mandate/website/get/params';
import {
  ContentWrap,
  Button,
  DonutChart,
  RestrictedBlock,
  VideoJSPlayer,
  OpacityTransitionComponent,
  Icon,
} from 'client/components/common';
import { VideoJSPlayer as VideoJSMobile } from 'client/components/common/mobile';
import { numberToCurrency } from 'client/utils/number-to-currency';
import { useAppDispatch, useTranslation, useResponsive, useAnalytics } from 'client/hooks';
import { CATEGORY_PAGE_ROUTE } from 'client/constants/routes';
import { MANDATE_RAISED_FIELD_OPTIONS } from 'constants/index';

import { setPopup } from 'client/redux/ui';

import { mandate as dummyData } from '../dummy-data';
import { InvestorData } from '../InvestorDetails';
import { VideoGallery } from '../VideoGallery';
import FoundersSlider from './FoundersSlider';

import css from './Hero.module.scss';

type Props = {
  className?: string;
  mandate: NonNullable<Mandate['mandate']>;
  onOfferClick: () => void;
  onInvestorClick: (data: InvestorData) => void;
};

export function Hero(props: Props) {
  const { mandate, className = '', onOfferClick } = props;
  const [isVideoGalleryOpen, setIsVideoGalleryOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const analytics = useAnalytics();
  const [isMobile] = useResponsive(['MOBILE']);
  const t = useTranslation().createPrefixedTranslationFunc('mandate');

  const leadInvestors: NonNullable<Mandate['mandate']>['leadInvestorInfo'] = mandate.leadInvestorInfo ?? [];
  const leadInvestorsToRender = isMobile ? leadInvestors.slice(0, 1) : leadInvestors.slice(0, 3);
  const leadInvestorsCounterValue = leadInvestors.length - 1;

  const onFullScreenClick = () => {
    setIsVideoGalleryOpen(true);
    analytics.gtag.event(`Open video gallery`, { mandateID: `${mandate._id}` });
  };

  const renderInvestmentDetails = (props: {
    target: number | string;
    raised: number | string;
    raisedFieldLabel?: string;
    valuation: number | string;
    progress: number;
    seriesFunding: string;
    isProgressVisible: boolean;
  }) => {
    const raisedFieldTitle =
      MANDATE_RAISED_FIELD_OPTIONS.find((m) => m.value === props.raisedFieldLabel)?.label ||
      MANDATE_RAISED_FIELD_OPTIONS[0].label;
    return (
      <div className={css.investmentDetails}>
        <div className={css.investmentDetailsBlock}>
          {props.isProgressVisible &&
            (isMobile ? (
              <div className={css.progressWrap}>
                <div className={css.progress} style={{ width: `${props.progress * 100}%` }} />
              </div>
            ) : (
              <div className={css.pieChartWrap}>
                <DonutChart className={css.pieChart} values={[{ color: '#0757d3', progress: props.progress }]} />
              </div>
            ))}
          <div className={css.values}>
            <div className={css.target}>
              {typeof props.target === 'number'
                ? `${t('investmentTarget')} ${numberToCurrency(props.target)}`
                : props.target}
            </div>
            <div className={css.raised}>
              {typeof props.raised === 'number'
                ? `${raisedFieldTitle} ${numberToCurrency(props.raised)}`
                : props.raised}
            </div>
          </div>
        </div>
        {(props.seriesFunding || props.valuation) && (
          <div className={css.investmentDetailsBlock}>
            <div className={css.round}>
              {props.seriesFunding && <div className={css.roundTitle}>{props.seriesFunding}</div>}
              {props.valuation && (
                <div className={css.roundValuation}>
                  {typeof props.valuation === 'number'
                    ? `${numberToCurrency(props.valuation)} ${t('investmentValuation')}`
                    : props.valuation}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderCategories = () => {
    return (
      <div className={css.categories}>
        {mandate?.categories?.map((category) => (
          <Link to={CATEGORY_PAGE_ROUTE.replace(':id', category.slug)} className={css.categoryTag} key={category._id}>
            {category.name}
          </Link>
        ))}
      </div>
    );
  };

  const clickOnInvesrors = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isMobile) {
      e.preventDefault();
      const scrolledEl = document.getElementById('investors');
      const mandate = document.getElementById('mandate');
      const hero = document.getElementById('hero');

      const tweener = { value: mandate?.scrollTop };

      if (scrolledEl && hero) {
        gsap.to(tweener, {
          duration: 1,
          value: scrolledEl.offsetTop + hero.offsetHeight,
          onUpdate: () => {
            mandate?.scroll({ top: tweener.value, behavior: 'auto' });
          },
        });
      }
    }
  };

  return (
    <div className={cn(css.hero, className)} id="hero">
      <div className={css.topSection}>
        <ContentWrap className={css.topSectionContent}>
          <div className={css.companyInfo}>
            <img className={css.companyLogo} src={mandate?.logo} alt={mandate?.legalName} />
            <p className={css.companyName}>{mandate?.legalName}</p>
            <p className={css.companyDescription}>{mandate?.shortDescription}</p>
            {isMobile && renderCategories()}
            <div className={css.companyActions}>
              <Button className={css.placeOfferBtn} onClick={onOfferClick}>
                {t('placeAnOffer')}
              </Button>
              <a href="#documents">
                <Button className={css.docsBtn}>
                  <Icon type="docs" className={css.docsBtnIcon} />
                  <span>{t('docs')}</span>
                </Button>
              </a>
            </div>
          </div>
          {!isMobile && renderCategories()}
        </ContentWrap>
        {mandate.clip && (
          <div className={css.topPlayer}>
            {isMobile ? (
              <VideoJSMobile
                id={mandate._id as string}
                videoSRC={mandate.clip}
                bindFullscreenClick={onFullScreenClick}
              />
            ) : (
              <VideoJSPlayer
                videoSRC={mandate.clip}
                isRepeated
                id={mandate._id as string}
                onExternalPause={isVideoGalleryOpen}
                bindFullscreenClick={onFullScreenClick}
              />
            )}
            {mandate.videoGallery && mandate.videoGallery.length > 0 && (
              <button type="button" className={css.galleryButton} onClick={onFullScreenClick}>
                <Icon type="watch-more" className={css.watchMoreIcon} />
                {!isMobile && <span className={css.galleryButtonLabel}>Watch more</span>}
              </button>
            )}
          </div>
        )}
      </div>
      <div className={css.bottomSection}>
        <ContentWrap className={css.bottomSectionContent}>
          <div className={css.infoBlock}>
            <div className={css.infoBlockTitle}>
              <div
                className={css.greenDot}
                style={mandate.investmentStatusColor ? { backgroundColor: mandate.investmentStatusColor } : undefined}
              />
              <p className={css.greenDotTitle}>{mandate.investmentStatusTitle || t('openInvestment')}</p>
            </div>
            <div className={css.infoBlockContent}>
              <RestrictedBlock
                fields={[
                  'progressBarPercentage',
                  'raised',
                  'seriesFunding',
                  'recruitmentTotalAmount',
                  'valuation',
                  'prevRounds',
                  'isProgressBarVisible',
                ]}
                fallback={renderInvestmentDetails({
                  progress: (dummyData.progressBarPercentage ?? 0) / 100,
                  raised: dummyData.raised,
                  raisedFieldLabel: dummyData.raisedFieldLabel,
                  seriesFunding: dummyData.seriesFunding ?? '',
                  target: dummyData.recruitmentTotalAmount,
                  valuation: dummyData.valuation,
                  isProgressVisible: dummyData.isProgressBarVisible,
                })}
                onUnlockBtnClick={() => dispatch(setPopup({ type: 'accreditation-questionnaire' }))}
              >
                {renderInvestmentDetails({
                  progress: (mandate.progressBarPercentage ?? 0) / 100,
                  raised: +(mandate.raised ?? 0),
                  raisedFieldLabel: mandate.raisedFieldLabel,
                  seriesFunding: mandate.seriesFunding ?? '',
                  target: +(mandate.recruitmentTotalAmount ?? 0),
                  valuation: mandate.valuation ?? 0,
                  isProgressVisible: !!mandate.isProgressBarVisible,
                })}
              </RestrictedBlock>
            </div>
          </div>
          {leadInvestorsToRender.length > 0 && (
            <div className={css.infoBlock}>
              <div className={css.leadInvestorsWrap}>
                <p className={css.infoBlockTitle}>{t('leadInvestors')}</p>
                <div className={css.infoBlockContent}>
                  {leadInvestors.length > 1 ? (
                    <a className={css.leadInvestors} onClick={clickOnInvesrors} href="#investors">
                      <div className={css.leadInvestorsLogos}>
                        {leadInvestorsToRender.map((investor, index) => (
                          <div className={css.leadInvestorsLogo} key={index}>
                            <div style={{ backgroundImage: `url(${investor.logo})` }} />
                          </div>
                        ))}
                      </div>
                      <div className={css.leadInvestorTitle}>{leadInvestorsToRender[0]?.name}</div>
                      {leadInvestorsCounterValue >= 1 && (
                        <div className={css.counter}>+{leadInvestorsCounterValue}</div>
                      )}
                    </a>
                  ) : (
                    <div className={css.leadInvestors} onClick={() => props.onInvestorClick(leadInvestorsToRender[0])}>
                      <div className={css.leadInvestorsLogos}>
                        {leadInvestorsToRender.map((investor, index) => (
                          <div className={css.leadInvestorsLogo} key={index}>
                            <div style={{ backgroundImage: `url(${investor.logo})` }} />
                          </div>
                        ))}
                      </div>
                      <div className={css.leadInvestorTitle}>{leadInvestorsToRender[0]?.name}</div>
                      {leadInvestorsCounterValue >= 1 && (
                        <div className={css.counter}>+{leadInvestorsCounterValue}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={css.infoBlock}>
            <div className={css.foundersWrap}>
              <p className={css.infoBlockTitle}>{t('founders')}</p>
              <div className={css.infoBlockContent}>
                {mandate.team && <FoundersSlider founders={mandate.team} className={css.foundersSlider} />}
              </div>
            </div>
          </div>
        </ContentWrap>
      </div>
      <OpacityTransitionComponent visible={isVideoGalleryOpen}>
        {mandate.videoGallery && mandate.videoGallery.length > 0 && (
          <VideoGallery
            mandateId={mandate._id}
            videos={mandate.videoGallery}
            onClose={() => setIsVideoGalleryOpen(false)}
          />
        )}
      </OpacityTransitionComponent>
    </div>
  );
}

export default Hero;
