import { UserRole, UserStatus } from 'models/user/interface';

export const getMandateFieldsByUserRoleAndStatus = (role?: UserRole, status?: UserStatus): Array<string> => {
  const PUBLIC_FIELDS = [
    '_id',
    'companyId',
    'isVisible',
    'clip',
    'avatarVideo',
    'videoGallery',
    'logo',
    'name',
    'legalName',
    'categories',
    'founded',
    'sourceOfInformation',
    'leadInvestorInfo',
    'shortDescription',
    'investmentStatusTitle',
    'investmentStatusColor',
    'about',
    'websiteLink',
    'companySize',
    'socials',
    'team',
  ];

  const LEVEL_2_3 = [
    'keyInformation',
    'investmentStatus',
    'recruitmentTotalAmount',
    'raised',
    'raisedFieldLabel',
    'progressBarPercentage',
    'isProgressBarVisible',
    'seriesFunding',
    'valuation',
    'country',
    'investmentTerms',
    'prevRounds',
    'termsOfDeal',
    'documents',
  ];

  if (!role || !status || role === 'VIEWER' || status === 'NOT_APPROVED') {
    return [];
  }

  if (role === 'INVESTOR_LEVEL_2' || role === 'INVESTOR_LEVEL_3') {
    return [...PUBLIC_FIELDS, ...LEVEL_2_3];
  }

  return PUBLIC_FIELDS;
};
