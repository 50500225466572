import qs from 'qs';
import React from 'react';
import cn from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useLocation, useRouteMatch } from 'react-router';
import { selectMenuState } from 'client/redux/ui/selectors';

import 'client/styles/index.scss';

import * as routes from 'client/constants/routes';
import shareImg from 'client/assets/shareimage.png';
import { useAppSelector, useAppDispatch } from 'client/hooks';
import favicon16 from 'client/assets/favicon/web/16x16/favicon.png';
import favicon32 from 'client/assets/favicon/web/32x32/favicon.png';
import faviconApple from 'client/assets/favicon/apple/favicon.png';

import { setPopup } from 'client/redux/ui';
import { Header, Footer, PopUpManager, SavingListStub, FooterDisclaimer } from 'client/components/common';
import BannerJoin from './common/BannerJoin';

import Main from './pages/Main';
import Company from './pages/Company';
import FAQ from './pages/FAQ';
import Category from './pages/Category';
import SearchResults from './pages/SearchResults';
import ClaimCompany from './pages/ClaimCompany/Form';
import ClaimCompanyThanks from './pages/ClaimCompany/Thanks';
import UserProfile from './pages/UserProfile';
import NotFound from './pages/NotFound';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyStatement from './pages/PrivacyPolicy';
import Disclaimer from './pages/Disclaimer';
import CyberSecurity from './pages/CyberSecurity';
import ConsentForm from './pages/ConsentForm';
import AccessibilityStatement from './pages/AccessibilityStatement';
import Mandate from './pages/Mandate';
import Mandates from './pages/Mandates';
import About from './pages/About';
import ClubPage from './pages/ClubPage';
import ContactUs from './pages/ContactUs';

import css from './Desktop.module.scss';

const DesktopApp: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isSignInRoute = !!useRouteMatch<{ id: string }>(routes.SIGN_IN_ROUTE);
  const isSignUpRoute = !!useRouteMatch<{ id: string }>(routes.SIGN_UP_ROUTE);
  const isActiveMenu = useAppSelector(selectMenuState) !== 'hidden';
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  React.useEffect(() => {
    (window as any).isUserAction = true;

    if (queryParams.token && queryParams.userId) {
      dispatch(
        setPopup({ type: 'auth', data: { initialAuthType: 'restore-password', initialCurrentView: 'emailProvider' } }),
      );
    }

    if (isSignInRoute || isSignUpRoute) {
      dispatch(setPopup({ type: 'auth', data: { initialAuthType: isSignInRoute ? 'sign-in' : 'sign-up' } }));
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [location.pathname]);

  const renderHelmet = () => {
    const seo = {
      title: `Catalyst Investors' Club - Exclusive Opportunities to Invest in Startups`,
      description: `Discover the most disruptive startups, find exclusive investment opportunities, and join Catalyst Investors' Club to invest alongside reputable lead investors.`,
    };
    const og = {
      title: `Catalyst Investors' Club - Exclusive Opportunities to Invest in Startups`,
      description: `Discover the most disruptive startups, find exclusive investment opportunities, and join Catalyst Investors' Club to invest alongside reputable lead investors.`,
      image: shareImg,
    };

    return (
      <Helmet>
        <title>{seo.title}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={`${faviconApple}`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32}`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${favicon16}`} />
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={og.title} />
        <meta property="og:description" content={og.description} />
        <meta property="og:image" content={og.image} />
      </Helmet>
    );
  };

  return (
    <>
      <BannerJoin />
      <div className={cn(css.app, isActiveMenu && css.fullHeight)}>
        {renderHelmet()}
        <Header />
        <Switch>
          <Route exact path={routes.COMPANY_PAGE_ROUTE}>
            <Company />
          </Route>
          <Route path={routes.MANDATE_PAGE_ROUTE}>
            <Mandate />
          </Route>
          <Route path={routes.MANDATES_PAGE_ROUTE}>
            <Mandates />
          </Route>
          <Route exact path={routes.ABOUT_PAGE_ROUTE}>
            <About />
          </Route>
          <Route exact path={routes.CLUB_PAGE_ROUTE}>
            <ClubPage />
          </Route>
          <Route exact path={routes.CONTACT_US_PAGE_ROUTE}>
            <ContactUs />
          </Route>
          <Route exact path={routes.FAQ_PAGE_ROUTE}>
            <FAQ />
          </Route>
          <Route exact path={routes.INVESTORS_CLUB_PAGE_ROUTE}>
            <div>Hello investors club</div>
          </Route>
          <Route exact path={routes.ADD_YOUR_COMPANY_PAGE_ROUTE}>
            <ClaimCompany />
          </Route>
          <Route exact path={routes.ADD_YOUR_COMPANY_THANKS_PAGE_ROUTE}>
            <ClaimCompanyThanks />
          </Route>
          <Route path={routes.USER_PROFILE_ROUTE}>
            <UserProfile />
          </Route>
          <Route exact path={routes.CATEGORY_PAGE_ROUTE}>
            <Category />
          </Route>
          <Route exact path={routes.SEARCH_RESULTS_PAGE_ROUTE}>
            <SearchResults />
          </Route>
          <Route exact path={routes.TERMS_OF_USE_PAGE_ROUTE}>
            <TermsOfUse />
          </Route>
          <Route exact path={routes.PRIVACY_POLICY_PAGE_ROUTE}>
            <PrivacyStatement />
          </Route>
          <Route exact path={routes.DISCLAIMER_PAGE_ROUTE}>
            <Disclaimer />
          </Route>
          <Route exact path={routes.CYBER_SECURITY_PAGE_ROUTE}>
            <CyberSecurity />
          </Route>
          <Route exact path={routes.CONSENT_FORM_PAGE_ROUTE}>
            <ConsentForm />
          </Route>
          <Route exact path={routes.ACCESSIBILITY_STATETMENT_PAGE_ROUTE}>
            <AccessibilityStatement />
          </Route>

          {/* stub, to prevent redirect to root at mobile app  */}
          <Route exact path={routes.SAVING_LIST_PAGE_ROUTE}>
            <SavingListStub />
          </Route>

          <Route exact path={routes.NOT_FOUND_PAGE_ROUTE}>
            <NotFound />
          </Route>

          <Route exact path={[routes.SIGN_IN_ROUTE, routes.MAIN_PAGE_ROUTE, routes.SIGN_UP_ROUTE]}>
            <Main />
          </Route>

          <Redirect to={routes.MAIN_PAGE_ROUTE.replace(':id?', '')} />
        </Switch>

        <Footer />
        <FooterDisclaimer />
        <PopUpManager className={css.popUpManager} />
      </div>
    </>
  );
};

export default DesktopApp;
