import React from 'react';
import cn from 'classnames';

import { useResponsive, useTranslation, useAppSelector, useAppDispatch } from 'client/hooks';
import { selectAuth } from 'client/redux/user/selectors';
import { setPopup } from 'client/redux/ui';

import { ControlPanel, Header } from 'client/components/common/mobile';
import { HelmetComponent, Icon, FaqSection, Button } from 'client/components/common';

import aboutBgD from 'client/assets/clubPage/about-bg-desk.png';
import aboutBgM from 'client/assets/clubPage/about-bg-mob.png';
import commonInfoImgDesk from 'client/assets/clubPage/common-info-img-desk.png';
import commonInfoImgMob from 'client/assets/clubPage/common-info-img-mob.png';
import cardItemImgDesk from 'client/assets/clubPage/card-image-desk.png';
import cardItemImgMob from 'client/assets/clubPage/card-image-mob.png';
import howItWorksBgDesk from 'client/assets/clubPage/how-it-works-bg-desk.png';
import howItWorksBgMob from 'client/assets/clubPage/how-it-works-bg-mob.png';

import { StickyCards } from './StickyCards';
import { StickyCardsMobile } from './StickyCardsMobile';

import { FAQCONTENT } from './faqData';

import css from './ClubPage.module.scss';

const ClubPage = () => {
  const [isMobile] = useResponsive(['MOBILE']);
  const { translate } = useTranslation();
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const onScrollToClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = e.currentTarget.dataset;

    if (id) {
      const scrolledEl = document.getElementById(id);

      if (scrolledEl) {
        scrolledEl.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  React.useEffect(() => {
    if (isMobile) {
      new StickyCardsMobile();
    } else {
      new StickyCards();
    }
  }, [isMobile]);

  return (
    <>
      <HelmetComponent
        title="Investors - Catalyst Investors' Club"
        description="We're more than just matchmakers, we're facilitators of meaningful connections between visionary investors and the most promising startups."
        ogTitle="Investors - Catalyst Investors' Club"
        ogDescription="We're more than just matchmakers, we're facilitators of meaningful connections between visionary investors and the most promising startups."
      ></HelmetComponent>
      <div className={css.pageWrapper} id="page-wrapper">
        {isMobile ? <Header className={css.pageHeader} /> : null}
        {isMobile ? <ControlPanel className={css.controlPanel} /> : null}

        <div className={css.contentWrapper}>
          <section className={css.about} style={{ backgroundImage: `url(${isMobile ? aboutBgM : aboutBgD})` }}>
            <div className={css.content}>
              <h1
                className={css.mainTitle}
                dangerouslySetInnerHTML={{ __html: translate('clubPage.intro.title') }}
              ></h1>
              <div className={css.text}>
                <p dangerouslySetInnerHTML={{ __html: translate('clubPage.intro.text') }}></p>
              </div>

              {!auth.isLoggedIn && (
                <Button
                  onClick={() => {
                    dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-up' } }));
                  }}
                  className={css.signUpBtn}
                >
                  <span>{translate('commonButtons.signUp')}</span>
                </Button>
              )}
              <button type="button" className={css.scrollDownBtn} onClick={onScrollToClick} data-id="common-info">
                <Icon type="scroll-down" />
              </button>
            </div>
          </section>
          <section className={css.commonInfo} id="common-info">
            <div className={css.content}>
              <div className={css.text}>
                <p dangerouslySetInnerHTML={{ __html: translate('clubPage.commonInfo.paragraph') }}></p>
                {!auth.isLoggedIn && (
                  <Button
                    onClick={() => {
                      dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-up' } }));
                    }}
                    className={css.signUpBtn}
                  >
                    <span>{translate('commonButtons.signUp')}</span>
                  </Button>
                )}
              </div>
              <div className={css.image}>
                <img src={commonInfoImgDesk} alt="common info" />
              </div>
            </div>
          </section>
          {isMobile && (
            <div className={css.mobileImage}>
              <img src={commonInfoImgMob} alt="common info" />
            </div>
          )}
          <section className={css.becomeAnInvestor}>
            <div className={css.content}>
              <div className={css.text}>
                <h2 dangerouslySetInnerHTML={{ __html: translate('clubPage.becomeAnInvestor.title') }}></h2>
                <p dangerouslySetInnerHTML={{ __html: translate('clubPage.becomeAnInvestor.text') }}></p>
              </div>
              <div className={cn(css.cardsList, 'cards')}>
                {Array.from({ length: 5 }, (v, i) => {
                  return (
                    <div className={cn(css.cardsListItem, 'card')} key={i}>
                      <div className={css.cardsListItemInfo}>
                        <div className={css.cardNumber}>#{i + 1}</div>
                        <div className={css.cardText}>
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: translate(`clubPage.becomeAnInvestor.cards.card${i + 1}.title`),
                            }}
                          ></h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: translate(`clubPage.becomeAnInvestor.cards.card${i + 1}.text`),
                            }}
                          ></p>
                        </div>
                        {!auth.isLoggedIn ? (
                          <Button
                            onClick={() => {
                              dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-up' } }));
                            }}
                            className={cn(css.signUpBtn, css.inCard)}
                          >
                            <span>{translate('commonButtons.signUp')}</span>
                          </Button>
                        ) : (
                          <div className={css.fakeBtn}></div>
                        )}
                      </div>
                      <div
                        className={css.cardsListItemImage}
                        style={{ backgroundImage: `url(${isMobile ? cardItemImgMob : cardItemImgDesk})` }}
                      >
                        <img src={cardItemImgMob} alt="" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <section className={css.howItWorks}>
            <div className={css.content}>
              <div className={css.text}>
                <h2 dangerouslySetInnerHTML={{ __html: translate('clubPage.howItWorks.title') }}></h2>
                <p dangerouslySetInnerHTML={{ __html: translate('clubPage.howItWorks.text') }}></p>
              </div>
              <div className={css.list}>
                {Array.from({ length: 5 }, (v, i) => {
                  return (
                    <div className={css.listItem} key={i} id={`listItem${i + 1}`}>
                      <div className={css.listItemWrapper}>
                        <div className={css.listItemWrapperText}>
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: translate(`clubPage.howItWorks.list.item${i + 1}.title`),
                            }}
                          ></h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: translate(`clubPage.howItWorks.list.item${i + 1}.text`),
                            }}
                          ></p>
                        </div>
                        <div className={css.listItemWrapperNumber}>#{i + 1}</div>
                      </div>
                      {i !== 4 && (
                        <button
                          type="button"
                          className={css.scrollDownBtn}
                          data-id={`listItem${i + 1}`}
                          onClick={onScrollToClick}
                        >
                          <Icon type="scroll-down" />
                        </button>
                      )}
                    </div>
                  );
                })}
                <div
                  className={css.howItWorksBg}
                  style={{ backgroundImage: `url(${isMobile ? howItWorksBgMob : howItWorksBgDesk})` }}
                ></div>
              </div>
              {!auth.isLoggedIn && (
                <Button
                  onClick={() => {
                    dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-up' } }));
                  }}
                  className={cn(css.signUpBtn)}
                >
                  <span>{translate('commonButtons.signUp')}</span>
                </Button>
              )}
            </div>
          </section>
          <section className={css.faq}>
            <div className={css.content}>
              <FaqSection data={FAQCONTENT} />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ClubPage;
