import React from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import cn from 'classnames';

import { ERROR } from 'endpoints/endpoint';
import { useAppDispatch, useTranslation, useResponsive } from 'client/hooks';
import { signIn } from 'client/redux/user/actions';
import { emailAndPassParamsSchema, Params } from 'api/auth/user/sign-in/params';
import { TextField } from 'client/components/common/form';
import Loader from 'client/components/common/Loader';

import arrowBackIconUrl from 'client/assets/header/arrow-back.svg';

import css from './EmailProvider.module.scss';

type Props = {
  onForgotClick: () => unknown;
  onBackBtnClick: () => unknown;
  onSuccess: () => unknown;
};

const API_ERRORS_TRANSLATIONS: Record<string, string> = {
  [ERROR.UNAUTHORIZED_REQUEST]: 'unauthorized',
};

const SignIn: React.FC<Props> = ({ onForgotClick, onBackBtnClick, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [error, setError] = React.useState('');
  const { translate: _t } = useTranslation('popups');
  const [isDesktop] = useResponsive('DESKTOP');
  const isKnownError = Object.values(API_ERRORS_TRANSLATIONS).includes(error);
  const formikContext = useFormik<Params>({
    enableReinitialize: true,
    validationSchema: emailAndPassParamsSchema,
    initialValues: {
      provider: 'emailAndPassword',
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setError('');

      const result = await dispatch(signIn(values));

      if (result.meta.requestStatus === 'rejected') {
        const rawError = result.payload as string;

        setError(API_ERRORS_TRANSLATIONS[rawError] ?? rawError);
      } else {
        onSuccess();
      }
    },
  });

  return (
    <FormikProvider value={formikContext}>
      <h6 className={css.formTitle}>{_t('login.emailFormsTitles.signIn')}</h6>
      <Form className={cn(css.form, css.signInForm, formikContext.isSubmitting && css.inactive)}>
        <div className={css.fields}>
          <div className={css.field}>
            <TextField name="email" label={_t('login.emailFormsLabels.email')} autoComplete="email" />
          </div>
          <div className={css.field}>
            <TextField
              name="password"
              type="password"
              label={_t('login.emailFormsLabels.password')}
              autoComplete="current-password"
            />
          </div>
        </div>

        {error && <div className={css.responseError}>{isKnownError ? _t(`login.errorLabels.${error}`) : error}</div>}

        <button type="submit" className={css.submitButton}>
          {_t('login.emailFormsButtons.signIn')}
        </button>

        {formikContext.isSubmitting && <Loader position="relative" className={css.loader} />}
      </Form>
      <div onClick={onBackBtnClick} className={css.backButton}>
        {isDesktop ? `←${_t('login.emailFormsButtons.back')}` : <img src={arrowBackIconUrl} alt="" />}
      </div>
      <div className={css.forgotButton} onClick={onForgotClick}>
        {_t('login.bottomForgotPassword')}
      </div>
    </FormikProvider>
  );
};

export default SignIn;
