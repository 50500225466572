import React from 'react';
import _ from 'lodash';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { format } from 'date-fns';

import { useAppSelector } from 'client/hooks';
import {
  getCurrentStep,
  getStep,
  getPersonalDetails,
} from 'utils/onboarding/accreditation-questionnaire/personal-details';
import { getFullData } from 'utils/onboarding/full-data';
import { selectUserOnboarding, selectUserEmail, selectUserRole } from 'client/redux/user/selectors';
import { InputType } from 'utils/onboarding/input-types';
import { StepInput, StageStep } from 'utils/onboarding/stage';
import { InputValue, Onboarding } from 'models/user/onboarding';
import { onboarding as onboardingApi } from 'client/redux/api/onboarding';
import { hubspot as hubspotApi } from 'client/redux/api/hubspot';
import { transitionClassNames } from 'client/utils/css-transition-classnames';
import countries from 'utils/countries.json';
import { statusTranslation } from 'utils/hubspot-investor-status';

import MultipleChoice from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/MultipleChoice';
import SingleChoice from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/SingleChoice';
import LongTextField from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/LongTextField';
import YesNo from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/YesNo';
import Form from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/Form';
import Select from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/Select';
import NavigationButtons from 'client/components/common/PopUpManager/AccreditationQuestionnaire/NavigationButtons';

import css from './PersonalDetails.module.scss';

interface Props {
  setStage: () => void;
  setActiveQuestion: (val: number) => void;
  setQuestionsLength: (val: number) => void;
}

const PersonalDetails = ({ setStage, setActiveQuestion, setQuestionsLength }: Props) => {
  const onBoarding = useAppSelector(selectUserOnboarding);
  const currentStepStart = getCurrentStep(onBoarding);
  const userEmail = useAppSelector(selectUserEmail);
  const role = useAppSelector(selectUserRole);

  const [currentStep, setCurrentStep] = React.useState(currentStepStart);

  const [personalDetails, personalDetailsState] = onboardingApi.endpoints.setPersonalDetails.useMutation();
  const [updateContact, updateContactResult] = hubspotApi.endpoints.updateContact.useMutation();

  const onSubmitHandler = async (val: Record<keyof Onboarding['form'], InputValue>) => {
    personalDetails({ ...val, intent: 'personal_details' });
  };

  const renderStep = (item: ReturnType<typeof getStep>, isSkippable: boolean) => {
    switch (item?.current.type) {
      case InputType.FORM:
        return (
          <Form
            fields={item?.current.inputs as Array<StepInput>}
            name={item.current.name}
            onSubmitHandler={onSubmitHandler}
          />
        );

      case InputType.TEXT:
        return <div>Text field {item.current.name}</div>;

      case InputType.TEXT_AREA:
        return <LongTextField info={item.current} onSubmitHandler={onSubmitHandler} isSkippable={isSkippable} />;

      case InputType.CHECKBOX:
        return (
          <div>
            <YesNo info={item.current} onSubmitHandler={onSubmitHandler} isSkippable={isSkippable} />
          </div>
        );

      case InputType.SELECT:
        if (item.current.multiple)
          return <MultipleChoice info={item.current} onSubmitHandler={onSubmitHandler} isSkippable={isSkippable} />;
        return <SingleChoice info={item.current} onSubmitHandler={onSubmitHandler} isSkippable={isSkippable} />;

      case InputType.AUTO_COMPLETE:
        return (
          <div>
            <Select info={item.current} onSubmitHandler={onSubmitHandler} isSkippable={isSkippable} />
          </div>
        );

      case InputType.DATE:
        return <div>Date field {item.current.name}</div>;

      default:
        return null;
    }
  };

  React.useEffect(() => {
    if (personalDetailsState.data?.success) {
      const fullData = getFullData(onBoarding);

      if (currentStep) {
        if (currentStep.current.name === 'personalDetails') {
          const country = _.values(onBoarding.form.countryOfResidence);
          const countryLabel = country ? countries.find((c) => c.value === country[0])?.label : '';

          const updateContactFunc = async () => {
            await updateContact({
              email: userEmail,
              firstname: onBoarding.form.firstName as string,
              lastname: onBoarding.form.lastName as string,
              date_of_birth: format(new Date(onBoarding.form.dateOfBirth as Date), 'yyyy-MM-dd'),
              country: countryLabel,
              investor_status_level: statusTranslation(role),
            });
          };
          updateContactFunc();
        }

        if (currentStep.current.name === 'hasNonResidentalCitizenship') {
          const updateContactFunc = async () => {
            await updateContact({
              email: userEmail,
              started_the_questionnaire: 'true',
            });
          };
          updateContactFunc();
        }

        const updatedCurrentStep = getStep(fullData, currentStep.current.name);
        console.log('updatedCurrentStep', updatedCurrentStep, currentStep.current.name);

        if (updatedCurrentStep?.next) {
          const nextStep = getStep(fullData, updatedCurrentStep.next.name);
          console.log('nextStep', nextStep);

          setCurrentStep(nextStep);
        } else {
          setStage();
        }
      }
    }
  }, [personalDetailsState]);

  React.useEffect(() => {
    const personalDetails = getPersonalDetails(onBoarding);
    const names = personalDetails.steps.map((item) => item.name);
    const name = currentStep?.current.name;
    if (names.length > 0 && name) {
      const activeStageIndex = names.indexOf(name);
      const questionsLength = personalDetails.steps.length;

      setActiveQuestion(activeStageIndex);
      setQuestionsLength(questionsLength);
    }
  }, [currentStep]);

  const onBackHandler = () => {
    if (currentStep?.prev) {
      const targetStep = getStep(onBoarding, currentStep.prev.name);
      setCurrentStep(targetStep);
    }
  };

  const isSkippable = <I extends keyof Onboarding['form']>() => {
    const step = currentStep?.current as StageStep<I>;
    if (!step) return false;

    return step?.type === InputType.FORM ? step.inputs.some(isInputRequired) : isInputRequired(step);

    function isInputRequired(input: StepInput<I>) {
      const schemaDescription = input.schema.describe();
      return schemaDescription?.tests?.some((test) => test.name === 'required');
    }
  };

  if (!currentStep) return null;

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={currentStep.current.name}
          timeout={300}
          classNames={contentTransitionClassNames}
          appear
          mountOnEnter
          unmountOnExit
        >
          {renderStep(currentStep, !isSkippable())}
        </CSSTransition>
      </SwitchTransition>
      <NavigationButtons
        clickOnBack={onBackHandler}
        isHiddenBack={currentStep && !currentStep.prev}
        isSkippable={!isSkippable()}
        clickOnSkip={onSubmitHandler}
        currentStep={currentStep}
      />
    </>
  );
};

const contentTransitionClassNames = transitionClassNames(css, 'personalDetailsTransition');

export default PersonalDetails;
