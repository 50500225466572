import React from 'react';
import _ from 'lodash';

import { useAppSelector, useTranslation, useAppDispatch } from 'client/hooks';
import { setPopup } from 'client/redux/ui';
import { selectUserOnboarding } from 'client/redux/user/selectors';
import { getQuestionnaire } from 'utils/onboarding/accreditation-questionnaire/questionnaire';

import { Input, InputType } from 'utils/onboarding/input-types';

import css from './QuestionnaireAnswers.module.scss';

const QuestionnaireAnswers = () => {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const onBoarding = useAppSelector(selectUserOnboarding);
  const questionnaireSteps = getQuestionnaire(onBoarding).steps as Array<Input>;
  console.log('questionnaireSteps', questionnaireSteps);

  const closeClickHandler = () => {
    dispatch(setPopup(null));
  };

  const renderAnswer = (step: Input) => {
    switch (step.type) {
      case InputType.SELECT:
        if (!step.value) return null;
        return (step.value as Array<string>).map((item, index) => {
          if (item.length > 1) return <li key={`${step.name}${index}`}>{item}</li>;
          const value = step.options.find((option) => option.value === item);
          if (value) return <li key={`${step.name}${index}`}>{translate(value?.label as string)}</li>;
          return '';
        });
      case InputType.CHECKBOX:
        return (
          <li>
            {step.value ? translate('popups.questionnaireAnswers.yes') : translate('popups.questionnaireAnswers.no')}
          </li>
        );
      case InputType.TEXT_AREA:
        return <li>{step.value ? (step.value as string) : ''}</li>;
      default:
        break;
    }
  };

  return (
    <>
      <div className={css.closeBtnMobile} onClick={closeClickHandler}></div>
      <div className={css.contentWrapper}>
        <div className={css.closeBtn} onClick={closeClickHandler}></div>
        <div className={css.contentWrapperI}>
          <div className={css.wrapper}>
            <h1>{translate('popups.questionnaireAnswers.title')}</h1>
            <div className={css.answers}>
              {questionnaireSteps.map((step, index) => {
                return (
                  <div className={css.answerWrapper} key={index}>
                    <div className={css.gradient}></div>
                    <div className={css.number}>{index + 1}</div>
                    <h3>{translate(step.label)}</h3>
                    <ul className={css.answer}>{renderAnswer(step)}</ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={css.blurredBlock}></div>;
    </>
  );
};

export default QuestionnaireAnswers;
