import React from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import cn from 'classnames';

import { useAppDispatch, useTranslation, useResponsive } from 'client/hooks';
import { forgotPassword } from 'client/redux/user/actions';
import { paramsSchema, Params } from 'api/auth/user/forgot-password/params';
import { TextField } from 'client/components/common/form';
import { Loader } from 'client/components/common';

import arrowBackIconUrl from 'client/assets/header/arrow-back.svg';

import css from './EmailProvider.module.scss';

type Props = {
  onBackClick: () => unknown;
  onSuccess: (email: string) => unknown;
};

const ForgotPassword: React.FC<Props> = ({ onBackClick, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [error, setError] = React.useState('');
  const { translate: _t } = useTranslation('popups');
  const [isDesktop] = useResponsive('DESKTOP');
  const formikContext = useFormik<Params>({
    enableReinitialize: true,
    validationSchema: paramsSchema,
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      setError('');

      const result = await dispatch(forgotPassword(values));

      if (result.meta.requestStatus === 'rejected') {
        setError(result.payload as string);
      } else {
        onSuccess(values.email);
      }
    },
  });
  return (
    <FormikProvider value={formikContext}>
      <h6 className={css.formTitle}>{_t('login.emailFormsTitles.forgotPassword')}</h6>
      <Form className={cn(css.form, css.forgotPasswordForm, formikContext.isSubmitting && css.inactive)}>
        <div className={css.fields}>
          <div className={css.field}>
            <TextField name="email" label={_t('login.emailFormsLabels.email')} autoComplete="email" />
          </div>
        </div>

        {error && <div className={css.responseError}>{error}</div>}
        {formikContext.isSubmitting && <Loader position="relative" />}

        <button type="submit" className={css.submitButton}>
          {_t('login.emailFormsButtons.forgotPassword')}
        </button>
      </Form>
      <div className={css.backButton} onClick={onBackClick}>
        {isDesktop ? `←${_t('login.emailFormsButtons.back')}` : <img src={arrowBackIconUrl} alt="" />}
      </div>
    </FormikProvider>
  );
};

export default ForgotPassword;
