import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { categories as categoriesApi } from 'client/redux/api/categories';
import Loader from '../Loader';
import { useTranslation, useAppDispatch, useAppSelector } from 'client/hooks';
import { setMenuState } from 'client/redux/ui';
import { selectUI } from 'client/redux/ui/selectors';
import { selectAuth } from 'client/redux/user/selectors';

import {
  ABOUT_PAGE_ROUTE,
  CONTACT_US_PAGE_ROUTE,
  FAQ_PAGE_ROUTE,
  ADD_YOUR_COMPANY_PAGE_ROUTE,
  CATEGORY_PAGE_ROUTE,
  CLUB_PAGE_ROUTE,
} from 'client/constants/routes';

import css from './Menu.module.scss';

const MENU_LINKS = [
  { name: 'aboutUs', link: ABOUT_PAGE_ROUTE },
  { name: 'clubPage', link: CLUB_PAGE_ROUTE },
  { name: 'faq', link: FAQ_PAGE_ROUTE },
  { name: 'addYourCompany', link: ADD_YOUR_COMPANY_PAGE_ROUTE },
  { name: 'contactUs', link: CONTACT_US_PAGE_ROUTE },
];

const Menu = () => {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { bannerJoin } = useAppSelector(selectUI);
  const auth = useAppSelector(selectAuth);

  const clickOnLink = () => {
    dispatch(setMenuState('hidden'));
  };
  const { data, isLoading } = categoriesApi.endpoints.getAllCategories.useQuery({ isVisible: true });
  const categories = data?.success ? data.data : [];

  return (
    <div className={cn(css.menuWrapper, !auth.isLoggedIn && bannerJoin === 'visible' && css.withOpenedBanner)}>
      <div className={css.filter}></div>
      <div className={css.content}>
        <div className={css.menu}>
          <div className={css.title}>{translate('menu.titles.menu')}</div>
          <div className={css.links}>
            {MENU_LINKS.map((link) => {
              return (
                <NavLink
                  to={link.link}
                  key={`${link.link}`}
                  className={(isActive) => {
                    return cn(css.navlink, isActive && css.activeLink);
                  }}
                  onClick={clickOnLink}
                >
                  {translate(`menu.menuItems.${link.name}`)}
                </NavLink>
              );
            })}
          </div>
        </div>
        <div className={css.categories}>
          <div className={css.title}>{translate('menu.titles.categories')}</div>

          <div className={css.links}>
            {isLoading && <Loader position="relative" className={css.loader} />}
            {categories &&
              categories.map((category) => {
                return (
                  <NavLink
                    to={`${CATEGORY_PAGE_ROUTE.replace(':id', category._id.toString())}`}
                    key={`${category._id.toString()}`}
                    className={(isActive) => {
                      return cn(css.navlink, isActive && css.activeLink);
                    }}
                    onClick={clickOnLink}
                  >
                    {category.name}
                  </NavLink>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
