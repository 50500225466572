import React from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import { FormikProvider, Form, useFormik } from 'formik';
import _ from 'lodash';
import { format } from 'date-fns';

import { useAppSelector, useTranslation, useAppDispatch } from 'client/hooks';
import { setPopup } from 'client/redux/ui';
import { Onboarding, InputValue } from 'models/user/onboarding';
import { InputType } from 'utils/onboarding/input-types';
import { selectUserOnboarding, selectUserEmail } from 'client/redux/user/selectors';
import { getContactDetails } from 'utils/onboarding/accreditation-finalization/contact-details';
import { StepInput } from 'utils/onboarding/stage';
import { validationSchema, initialValues } from 'utils/onboarding';
import { MediaType } from 'client/types';
import { onboarding as onboardingApi } from 'client/redux/api/onboarding';
import { hubspot as hubspotApi } from 'client/redux/api/hubspot';
import countries from 'utils/countries.json';

import Text from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/Form/TextField';
import Select from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/Form/Select';
import DatePickerField from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/Form/DatePicker';
import UploadMedia from 'client/components/common/form/Onboarding/UploadMedia';
import Button from 'client/components/common/Button';

import css from './ContactDetails.module.scss';

const ContactDetails = () => {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector(selectUserEmail);
  const onBoarding = useAppSelector(selectUserOnboarding);
  const contactDetailsData = getContactDetails(onBoarding).steps as Array<StepInput>;
  const [contactDetails, contactDetailsState] = onboardingApi.endpoints.setContactDetails.useMutation();
  const [updateContact, updateContactResult] = hubspotApi.endpoints.updateContact.useMutation();

  //split fields to personal data / address / phone number / photo id
  const personalDetailsFields = contactDetailsData.slice(0, 5) as Array<StepInput>;
  const addressDetailsFields = contactDetailsData.slice(5, 11) as Array<StepInput>;
  const phoneDetailsField = contactDetailsData[11] as StepInput;
  const photoIdDetailsField = contactDetailsData[12] as StepInput;

  const formikContext = useFormik<Onboarding['form']>({
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    initialValues: initialValues(contactDetailsData),
    validate: async (values: Onboarding['form']) => {
      const errors: Record<string, string> = {};
      const schema = validationSchema(contactDetailsData);

      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const { inner } = error as yup.ValidationError;
        inner.forEach((e) => {
          if (e.path && e.type) errors[e.path] = e.errors.join(', ');
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      console.log('Submit');
      // await contactDetails({ ...values, intent: 'contact_details' });

      const notEmptyValues = {} as Record<keyof Onboarding['form'], InputValue>;
      for (const key in values) {
        if (values[key as keyof Onboarding['form']] && Array.isArray(values[key as keyof Onboarding['form']])) {
          if ((values[key as keyof Onboarding['form']] as Array<any>).length > 0) {
            notEmptyValues[key as keyof Onboarding['form']] = values[key as keyof Onboarding['form']];
          }
        } else if (values[key as keyof Onboarding['form']]) {
          notEmptyValues[key as keyof Onboarding['form']] = values[key as keyof Onboarding['form']];
        }
      }

      const isEmptyValues = _.isEmpty(notEmptyValues);

      if (!isEmptyValues) {
        await contactDetails({ ...values, intent: 'contact_details' });

        const country = _.values(values.countryOfResidence);
        const countryLabel = country ? countries.find((c) => c.value === country[0])?.label : '';
        await updateContact({
          email: userEmail,
          firstname: values.firstName as string,
          lastname: values.lastName as string,
          date_of_birth: format(new Date(values.dateOfBirth as Date), 'yyyy-MM-dd'),
          country: countryLabel,
          phone: values.phone ? (values.phone as string) : '',
        });
      }
    },
  });

  React.useEffect(() => {
    if (contactDetailsState.data?.success) {
      dispatch(setPopup(null));
    }
  }, [contactDetailsState]);

  const closeClickHandler = () => {
    if (formikContext.dirty) {
      const result = confirm(translate('onboardingFinalization.contactDetails.closeWindowConfirm'));
      if (result) {
        dispatch(setPopup(null));
      }
    } else {
      dispatch(setPopup(null));
    }
  };

  const renderField = (input: StepInput) => {
    switch (input.type) {
      case InputType.TEXT:
        return <Text name={input.name} label={input.label} withUpperLabel={false} />;
      case InputType.AUTO_COMPLETE:
        return <Select name={input.name} label={input.label} options={input.options} />;
      case InputType.DATE:
        return <DatePickerField name={input.name} label={input.label} />;
      case InputType.MEDIA:
        return (
          <UploadMedia
            name={input.name}
            accept=".jpg, .jpeg, .png"
            acceptText={translate('onboardingFinalization.contactDetails.uploadMedia.acceptFiles')}
            fakeBtn={translate('onboardingFinalization.contactDetails.uploadMedia.fakeBtnLabel')}
            value={formikContext.values[`${input.name}`] as MediaType}
          />
        );
      default:
        return null;
    }
  };

  const personalDetailsRender = () => {
    return personalDetailsFields.map((item) => {
      if (item.name === 'countryOfResidence') {
        return (
          <div className={cn(css.fieldWrapper, css.long)} key={item.name}>
            {renderField(item)}
          </div>
        );
      }
      return (
        <div className={css.fieldWrapper} key={item.name}>
          {renderField(item)}
        </div>
      );
    });
  };

  const adressDetailsRender = () => {
    return addressDetailsFields.map((item) => {
      if (item.name === 'addressLine_1' || item.name === 'addressLine_2') {
        return (
          <div className={cn(css.fieldWrapper, css.long)} key={item.name}>
            {renderField(item)}
          </div>
        );
      }
      return (
        <div className={css.fieldWrapper} key={item.name}>
          {renderField(item)}
        </div>
      );
    });
  };

  return (
    <FormikProvider value={formikContext}>
      <Form autoComplete="off">
        <div className={css.closeBtnMobile} onClick={closeClickHandler}></div>
        <div className={css.formWrapper}>
          <div className={css.closeBtn} onClick={closeClickHandler}></div>
          <div className={css.formWrapperI}>
            <div className={css.wrapper}>
              <h1>{translate('onboardingFinalization.contactDetails.title')}</h1>
              <div className={css.form}>
                <div className={css.personalDetails}>{personalDetailsRender()}</div>
                <h3 className={css.addressTitle}>{translate('onboardingFinalization.contactDetails.addressTitle')}</h3>
                <div className={css.addressDetails}>{adressDetailsRender()}</div>
                <h3 className={css.phoneTitle}>{translate('onboardingFinalization.contactDetails.phoneTitle')}</h3>
                {phoneDetailsField && (
                  <div className={css.phone}>
                    <div className={cn(css.fieldWrapper, css.long)}>{renderField(phoneDetailsField)}</div>
                  </div>
                )}
                <h3 className={css.photoIdTitle}>{translate('onboardingFinalization.contactDetails.photoIdTitle')}</h3>
                <div className={css.photoId}>
                  <div className={cn(css.fieldWrapper, css.long)}>{renderField(photoIdDetailsField)}</div>
                </div>
                <div className={css.submitWrapper}>
                  <Button type="submit" disabled={!formikContext.dirty} className={css.submitButton}>
                    <span className={cn(css.btnText, contactDetailsState.isLoading && css.loading)}>
                      {translate('onboardingFinalization.submitBtn')}
                    </span>
                    <div className={cn(css.loaderWrapper, contactDetailsState.isLoading && css.loading)}>
                      <div className={css.loader}></div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={css.blurredBlock}></div>
      </Form>
    </FormikProvider>
  );
};

export default ContactDetails;
